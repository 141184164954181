import { Injectable } from '@angular/core';

import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../../shared/interfaces/vocabulary.interface';
import { Tool } from '../../../../interfaces/tool-interfaces/tool.interface';
import { ToolForm } from '../../../../interfaces/form-interfaces/toolForm.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ToolHelperService {
  toolDropdowns: VocabularyType[] = [VocabularyType.irNameType, VocabularyType.irResourceTypeGeneral];

  constructor(private contributorsDashboardHelperService: ContributorsDashboardHelperService) {}

  toolFormModelToToolInterface(toolFormModel: ToolForm): Tool {
    const tool: Tool = {
      // Metadata Subform
      name: toolFormModel.metadata.name,
      author: toolFormModel.metadata.author,
      description: toolFormModel.metadata.description,
      keywords: this.contributorsDashboardHelperService.cleanArray(toolFormModel.metadata.keywords),
      license: toolFormModel.metadata.license,
      versionDate: toolFormModel.metadata.versionDate,
      deprecated: toolFormModel.metadata.deprecated,
      scientificDomains: this.contributorsDashboardHelperService.cleanArray(
        toolFormModel.metadata.scientificCategorisation.map((domain: any) => {
          return {
            scientificDomain: domain.scientificDomain,
            scientificSubdomain: domain.scientificSubdomain,
          };
        })
      ),
      targetInfrastructure: this.contributorsDashboardHelperService.cleanArray(
        toolFormModel.metadata.targetInfrastructure
      ),
    } as Tool;

    return tool;
  }

  toolInterfaceToToolFormModel(tool: Tool, vocabularies: { [key: string]: Vocabulary[] } | null = null): ToolForm {
    const toolForm: ToolForm = {
      metadata: {
        name: tool.name,
        author: tool.author,
        description: tool.description,
        keywords: tool.keywords?.length ? [...tool.keywords] : [''],
        license: tool.license,
        versionDate: tool.versionDate,
        deprecated: tool.deprecated,
        scientificCategorisation: tool.scientificDomains.length
          ? tool.scientificDomains.map((domain: any) => {
              return {
                scientificDomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificDomain,
                  domain.scientificDomain
                ),
                scientificSubdomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificSubdomain,
                  domain.scientificSubdomain
                ),
              };
            })
          : [{ scientificDomain: '', scientificSubdomain: '' }],
        targetInfrastructure: this.contributorsDashboardHelperService.cleanArray(tool.targetInfrastructure)[0],
      },
      content: {
        // toscaFile: tool.toscaFile ?? '',
      },
    } as ToolForm;

    return toolForm;
  }

  prepareToolForPreview() {
    this.toolDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
