import { Injectable } from '@angular/core';
import { concatMap, filter, map, Observable, take } from 'rxjs';
import { DatasourceBundle, DatasourceFull } from '../../../interfaces/datasource-interfaces/datasource.interface';
import { selectDatasource } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { Store } from '@ngrx/store';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { HttpClient } from '@angular/common/http';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { DatasourceForm } from '../../../interfaces/form-interfaces/datasourceForm.interface';
import { DatasourceHelperService } from './helpers/datasource-helper.service';

@Injectable({
  providedIn: 'root',
})
export class DatasourceService extends ContributorsDashboardEntityService<DatasourceFull> {
  datasource$: Observable<DatasourceFull | null> = new Observable<DatasourceFull | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private datasourceHelperService: DatasourceHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'datasources');
    this.datasource$ = this.store.select(selectDatasource);
  }

  getDatasource(datasourceId: string): Observable<DatasourceFull> {
    return this.get(datasourceId);
  }

  getDatasources(
    contributorId?: string,
    from?: number,
    query?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<DatasourceFull>> {
    return this.getAll(from, query, contributorId, status, active);
  }

  editDatasource(datasourceModel: DatasourceForm): Observable<string> {
    return this.datasource$.pipe(
      take(1),
      filter((datasourceFull: any) => !!datasourceFull),
      concatMap((datasourceFull: DatasourceFull) =>
        this.editEntity(this.updateDatasourceRequest(datasourceModel, datasourceFull))
      ),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully updated ${res.service?.service?.name} Datasource Record!`
        )
      )
    );
  }

  reviewDatasource(
    action: 'approve' | 'reject',
    datasourceWorkflowId: string,
    notes: string,
    resubmit: boolean,
    comments: any = null
  ): Observable<string> {
    return this.datasource$.pipe(
      concatMap((datasource) =>
        this.approveRejectEntity(action, datasource!, datasourceWorkflowId, notes, resubmit, comments)
      ),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} datasource!`
        )
      )
    );
  }

  toggleDatasourceActive(datasourceFull: DatasourceFull): Observable<DatasourceFull> {
    datasourceFull = {
      ...datasourceFull,
      datasource: {
        ...datasourceFull.datasource,
        active: !datasourceFull.datasource.active,
      },
    };

    return this.datasource$.pipe(
      take(1),
      concatMap(() => this.update(datasourceFull)),
      concatMap((res) => {
        if (res) {
          const state = datasourceFull?.datasource.active ? 'Activated' : 'Deactivated';
          const { name } = datasourceFull.service.service;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`The Datasource "${name}" has been ${state} successfully!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update datasource state.');
        }
      })
    );
  }

  updateDatasourceRequest(datasourceModel: DatasourceForm, datasourceFull: DatasourceFull): DatasourceFull {
    const { datasource, service, oaiPmhInfo, datasourceType } =
      this.datasourceHelperService.datasourceFormModelToDatasourceServiceInterface(datasourceModel);
    return {
      datasource: {
        ...datasourceFull.datasource,
        oaiPmhInfo,
        datasourceType,
        datasource: {
          ...datasourceFull.datasource.datasource!,
          ...datasource,
        },
      },
      service: {
        ...datasourceFull.service,
        service: {
          ...datasourceFull.service.service!,
          ...service,
        },
      },
    };
  }

  offboardDatasource(datasourceId: string): Observable<string> {
    return this.offboardEntity(datasourceId, 'Datasource').pipe(
      take(1),
      filter((res) => !!res),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully offboarded ${datasourceId} datasource record!`
        )
      )
    );
  }
}
