import { Injectable } from '@angular/core';
import { MultimediaPair, ServiceProviderDomain } from '../../../../interfaces/additionals.interface';
import { Service, ServiceBundle } from '../../../../interfaces/service-interfaces/service.interface';
import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { ServiceForm } from '../../../../interfaces/form-interfaces/serviceForm.interface';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../../shared/interfaces/vocabulary.interface';
import { ServiceClassTier } from '../../../../interfaces/service-interfaces/additionals-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceHelperService {
  serviceDropdowns: VocabularyType[] = [
    VocabularyType.language,
    VocabularyType.scientificDomain,
    VocabularyType.scientificSubdomain,
    VocabularyType.category,
    VocabularyType.subcategory,
    VocabularyType.targetUser,
    VocabularyType.country,
    VocabularyType.trl,
    VocabularyType.trContentResourceType,
    VocabularyType.orderType,
  ];

  constructor(private contributorsDashboardHelperService: ContributorsDashboardHelperService) {}

  serviceFormModelToServiceInterface(serviceFormModel: ServiceForm): Service {
    const service: Service = {
      // Basic Subform
      name: serviceFormModel.basic.name,
      abbreviation: serviceFormModel.basic.abbreviation,
      webpage: serviceFormModel.basic.webpage,
      // Marketing Subform
      description: serviceFormModel.marketing.description,
      tagline: serviceFormModel.marketing.tagline,
      logo: serviceFormModel.marketing.logo,
      languageAvailabilities: [...serviceFormModel.marketing.languages],
      // Classification Subform
      scientificDomains: serviceFormModel.classification.scientificCategorisation.map((domain: any) => {
        return {
          scientificDomain: domain.scientificDomain,
          scientificSubdomain: domain.scientificSubdomain,
        };
      }),
      categories: serviceFormModel.classification.categorisation.map((cat: any) => {
        return {
          category: cat.category,
          subcategory: cat.subcategory,
        };
      }),
      targetUsers: [...serviceFormModel.classification.targetUsers],
      tags: [...serviceFormModel.classification.tags],
      // Location Subform
      resourceGeographicLocations: [...serviceFormModel.location.geographicLocations],
      // Contact Subform
      mainContact: {
        firstName: serviceFormModel.contact.mainContact.firstName,
        lastName: serviceFormModel.contact.mainContact.lastName,
        email: serviceFormModel.contact.mainContact.email,
        phone: serviceFormModel.contact.mainContact.phone,
        position: serviceFormModel.contact.mainContact.position,
        organisation: serviceFormModel.contact.mainContact.organisation,
      },
      // Maturity Subform
      trl: serviceFormModel.maturity.technologyReadinessLevel,
      certifications: [...serviceFormModel.maturity.certifications],
      standards: [...serviceFormModel.maturity.standards],
      openSourceTechnologies: [...serviceFormModel.maturity.openSourceTechnologies],
      // Management Subform
      helpdeskPage: serviceFormModel.management.helpdeskPage,
      helpdeskEmail: serviceFormModel.management.helpdeskEmail,
      securityContactEmail: serviceFormModel.management.securityContactEmail,
      userManual: serviceFormModel.management.userManual,
      termsOfUse: serviceFormModel.management.termsOfUse,
      privacyPolicy: serviceFormModel.management.privacyPolicy,
      accessPolicy: serviceFormModel.management.accessPolicy,
      classTier: {
        accessPolicy: serviceFormModel.management.userAccessPolicy,
      } as ServiceClassTier,
      trainingInformation: serviceFormModel.management.trainingInformation,
      statusMonitoring: serviceFormModel.management.statusMonitoring,
      maintenance: serviceFormModel.management.maintenance,
      // Order Subform
      orderType: serviceFormModel.order.orderType,
      order: serviceFormModel.order.orderURL,
    } as Service;

    return service;
  }

  serviceInterfaceToServiceFormModel(
    serviceBundle: ServiceBundle,
    vocabularies: { [key: string]: Vocabulary[] } | null = null
  ): ServiceForm {
    const serviceFormModel: ServiceForm = {
      basic: {
        name: serviceBundle.service?.name,
        abbreviation: serviceBundle.service?.abbreviation,
        webpage: serviceBundle.service?.webpage,
        sites: serviceBundle.sites?.length
          ? serviceBundle.sites?.map((site) => {
              return {
                siteName: site.name,
                urls: site.endpoints?.length
                  ? site.endpoints.map((endpoint) => {
                      return {
                        endpoint: endpoint.url,
                        name: endpoint.name,
                        type: endpoint.type,
                      };
                    })
                  : [{ endpoint: '', name: '', type: '' }],
              };
            })
          : [
              {
                siteName: '',
                urls: [{ endpoint: '', name: '', type: '' }],
              },
            ],
      },
      marketing: {
        description: serviceBundle.service?.description,
        tagline: serviceBundle.service?.tagline,
        logo: serviceBundle.service?.logo,
        languages: serviceBundle.service?.languageAvailabilities?.length
          ? serviceBundle.service?.languageAvailabilities?.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.language, val)
            )!
          : [''],
      },
      classification: {
        scientificCategorisation: serviceBundle.service?.scientificDomains?.length
          ? serviceBundle.service?.scientificDomains?.map((domain: ServiceProviderDomain) => {
              return {
                scientificDomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificDomain,
                  domain.scientificDomain
                ),
                scientificSubdomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificSubdomain,
                  domain.scientificSubdomain
                ),
              };
            })!
          : [{ scientificDomain: '', scientificSubdomain: '' }],
        categorisation: serviceBundle.service?.categories?.length
          ? serviceBundle.service?.categories?.map((cat: any) => {
              return {
                category: this.convertValueToLabel(vocabularies, VocabularyType.category, cat.category),
                subcategory: this.convertValueToLabel(vocabularies, VocabularyType.subcategory, cat.subcategory),
              };
            })!
          : [{ category: '', subcategory: '' }],
        targetUsers: serviceBundle.service?.targetUsers?.length
          ? serviceBundle.service?.targetUsers?.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.targetUser, val)
            )!
          : [''],
        tags: serviceBundle.service?.tags?.length ? [...serviceBundle.service?.tags] : [''],
      },
      location: {
        geographicLocations: serviceBundle.service?.resourceGeographicLocations?.length
          ? serviceBundle.service?.resourceGeographicLocations?.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.geographicLocation, val)
            )!
          : [''],
      },
      contact: {
        mainContact: {
          firstName: serviceBundle.service?.mainContact?.firstName,
          lastName: serviceBundle.service?.mainContact?.lastName,
          email: serviceBundle.service?.mainContact?.email,
          phone: serviceBundle.service?.mainContact?.phone,
          position: serviceBundle.service?.mainContact?.position,
          organisation: serviceBundle.service?.mainContact?.organisation,
        },
      },
      maturity: {
        technologyReadinessLevel: serviceBundle.service?.trl
          ? this.convertValueToLabel(vocabularies, VocabularyType.trl, serviceBundle.service?.trl)
          : null,
        certifications: serviceBundle.service?.certifications?.length
          ? [...serviceBundle.service?.certifications]
          : [''],
        standards: serviceBundle.service?.standards?.length ? [...serviceBundle.service?.standards] : [''],
        openSourceTechnologies: serviceBundle.service?.openSourceTechnologies?.length
          ? [...serviceBundle.service?.openSourceTechnologies]
          : [''],
      },
      management: {
        helpdeskPage: serviceBundle.service?.helpdeskPage,
        helpdeskEmail: serviceBundle.service?.helpdeskEmail,
        securityContactEmail: serviceBundle.service?.securityContactEmail,
        userManual: serviceBundle.service?.userManual,
        termsOfUse: serviceBundle.service?.termsOfUse,
        privacyPolicy: serviceBundle.service?.privacyPolicy,
        accessPolicy: serviceBundle.service?.accessPolicy,
        userAccessPolicy: serviceBundle.service?.classTier?.accessPolicy,
        trainingInformation: serviceBundle.service?.trainingInformation,
        statusMonitoring: serviceBundle.service?.statusMonitoring,
        maintenance: serviceBundle.service?.maintenance,
      },
      order: {
        orderType: serviceBundle.service?.orderType
          ? this.convertValueToLabel(vocabularies, VocabularyType.orderType, serviceBundle.service?.orderType)
          : null,
        orderURL: serviceBundle.service?.order,
      },
    };

    return serviceFormModel;
  }

  prepareServiceForPreview() {
    this.serviceDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
