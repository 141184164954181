import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../../../../../core/interfaces/generic/api-response';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { contributorsDashboardEndpoints } from '../../contributors-dashboard-endpoints.config';
import { ServiceType } from '../../../interfaces/offer-interfaces/serviceType.interface';
import { Offer } from '../../../interfaces/offer-interfaces/offer.interface';
import { VocabularyType } from '../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../shared/interfaces/vocabulary.interface';
import { NameAcceptedNotes } from '../../../interfaces/additionals.interface';
import { ToolBundle, ToolDto } from '../../../interfaces/tool-interfaces/tool.interface';
import { EntityForReview } from '../../../interfaces/review-entity-interfaces/review-entity.interface';
import { EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN } from '../../../../../../endpoints.config';

@Injectable({
  providedIn: 'root',
})
export abstract class ContributorsDashboardService<T = any> {
  constructor(protected http: HttpClient, private entity: string) {}

  baseEntityUrl = (contributorsDashboardEndpoints as any)[this.entity];

  taskNames: { [key: string]: string } = {
    providers: 'task-review-contibutor',
    services: 'task-review-service',
    datasources: 'task-review-datasource',
    trainingResources: 'task-review-training',
    interoperabilityRecords: 'task-review-ifg',
  };

  getVocabularyByType(type: VocabularyType) {
    const url = `${contributorsDashboardEndpoints.vocabulary.baseUrl}${contributorsDashboardEndpoints.vocabulary.endpoints.getByType}/?type=${type}`;
    return this.http.get<ApiResponse<Vocabulary[]>>(url).pipe(map((data) => data.result));
  }

  get(id: string): Observable<T> {
    let url = `${this.baseEntityUrl.baseUrl}`;
    let params = new HttpParams();
    if (id) params = params.set('id', id);

    return this.http.get<ApiResponse<T>>(url, { params }).pipe(map((data) => data.result));
  }

  getAll(
    from?: number,
    query?: string,
    contributorId?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<T>> {
    let url = `${this.baseEntityUrl.baseUrl}${contributorsDashboardEndpoints.commonActions.getAll}`;
    let params = new HttpParams();
    if (contributorId) {
      const id = encodeURIComponent(contributorId);
      params = params.set('keyword', id).set('quantity', 50);
    } else {
      if (from) params = params.set('from', from);
      if (query) params = params.set('keyword', query);
    }

    if (status) {
      if (status == 'draft') params = params.set('draft', true);
      else params = params.set('status', status);
    }

    if (active !== undefined) params = params.set('active', active);
    const options = {
      params,
      context: new HttpContext().set(EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN, true),
    };

    return this.http.get<ApiResponse<ContributorsApiResponse<T>>>(url, options).pipe(map((item) => item.result));
  }

  create(entity: T): Observable<T> {
    let url = `${this.baseEntityUrl.baseUrl}`;
    return this.http.post<ApiResponse<T>>(url, entity).pipe(map((data) => data.result));
  }

  update(entity: T): Observable<T> {
    const url = `${this.baseEntityUrl.baseUrl}`;
    return this.http.put<ApiResponse<T>>(url, entity).pipe(map((data) => data.result));
  }

  delete(id: string): Observable<T> {
    id = encodeURIComponent(id);
    const params = new HttpParams().set('id', id);
    const url = `${this.baseEntityUrl.baseUrl}`;
    return this.http.delete<ApiResponse<T>>(url, { params }).pipe(map((data) => data.result));
  }

  offboard(id: string): Observable<T> {
    let params = new HttpParams().append('id', id);
    const url = `${this.baseEntityUrl.offboardUrl}${contributorsDashboardEndpoints.commonActions.offboard}`;
    return this.http.post<ApiResponse<T>>(url, null, { params }).pipe(map((data) => data.result));
  }

  review(
    profileType: 'ec' | 'eot',
    nameAcceptedNotes: NameAcceptedNotes,
    entityId: string
  ): Observable<ApiResponse<any>> {
    const id = encodeURIComponent(entityId);
    const url = `${contributorsDashboardEndpoints.catalogueReview.baseUrl}${
      profileType == 'ec' ? contributorsDashboardEndpoints.catalogueReview.endpoints.ec : ''
    }${(contributorsDashboardEndpoints.catalogueReview.endpoints as any)[this.entity]}/${id}`;
    return this.http.put<ApiResponse<any>>(url, nameAcceptedNotes);
  }

  // EntityForReview
  getEntityForReview(
    from?: number,
    query?: string,
    taskType?: string[]
  ): Observable<{ count: number; items: EntityForReview[]; page: number; size: number }> {
    let url = `${contributorsDashboardEndpoints.catalogueReview.baseUrl}${contributorsDashboardEndpoints.catalogueReview.endpoints.tasks}`;
    let params = new HttpParams().set('size', '10');
    if (from !== undefined) {
      params = params.set('page', from.toString());
    }

    if (taskType && taskType.length > 0) {
      taskType.forEach((type) => {
        params = params.append('taskType', type.toUpperCase());
      });
    }

    if (query) {
      params = params.set('businessKey', query);
    }
    const options = {
      params,
      context: new HttpContext().set(EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN, true),
    };

    return this.http
      .get<ApiResponse<{ count: number; items: EntityForReview[]; page: number; size: number }>>(url, options)
      .pipe(map((response) => response.result));
  }

  // Service
  evaluateService(key: string): Observable<ApiResponse<any>> {
    key = encodeURIComponent(key);
    let url = `${contributorsDashboardEndpoints.catalogueReview.baseUrl}${contributorsDashboardEndpoints.catalogueReview.endpoints.evaluateService}/${key}`;
    return this.http.post<ApiResponse<any>>(url, null).pipe(map((data) => data.result));
  }

  // Tools
  getTools(
    from?: number,
    query?: string,
    contributorId?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<ToolBundle>> {
    let url = `${contributorsDashboardEndpoints.tools.baseUrl}`;

    let params = new HttpParams();
    if (contributorId) {
      const id = encodeURIComponent(contributorId);
      params = params.set('keyword', id).set('quantity', 50);
    } else {
      if (from) params = params.set('from', from);
      if (query) params = params.set('keyword', query);
    }

    if (status) {
      if (status == 'draft') params = params.set('draft', true);
      else params = params.set('status', status);
    }

    if (active !== undefined) params = params.set('active', active);
    const options = {
      params,
      context: new HttpContext().set(EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN, true),
    };
    return this.http
      .get<ApiResponse<ContributorsApiResponse<ToolBundle>>>(url, options)
      .pipe(map((item) => item.result));
  }

  getTool(toolId: string): Observable<ToolDto> {
    toolId = encodeURIComponent(toolId);
    const url = `${contributorsDashboardEndpoints.tools.baseUrl}/${toolId}`;
    return this.http.get<ApiResponse<ToolDto>>(url).pipe(map((data) => data.result));
  }

  getToolDetails(toolId: string): Observable<ToolBundle> {
    toolId = encodeURIComponent(toolId);
    const url = `${contributorsDashboardEndpoints.tools.baseUrl}${contributorsDashboardEndpoints.tools.endpoints.details}/${toolId}`;
    return this.http.get<ApiResponse<ToolBundle>>(url).pipe(map((data) => data.result));
  }

  updateTool(toolBundle: ToolBundle): Observable<T> {
    const id = encodeURIComponent(toolBundle.id);
    const url = `${contributorsDashboardEndpoints.tools.baseUrl}${id}`;
    return this.http.put<ApiResponse<T>>(url, toolBundle).pipe(map((data) => data.result));
  }

  // deleteTool(toolId: string): Observable<ToolBundle> {
  //   const id = encodeURIComponent(toolId);
  //   const params = new HttpParams().set('id', id);
  //   const url = `${contributorsDashboardEndpoints.tools.baseUrl}`;
  //   return this.http.delete<ApiResponse<ToolBundle>>(url, { params }).pipe(map((data) => data.result));
  // }

  reviewTool(nameAcceptedNotes: NameAcceptedNotes, entityId: string): Observable<any> {
    const id = encodeURIComponent(entityId);
    const url = `${contributorsDashboardEndpoints.tools.baseUrl}/${id}${contributorsDashboardEndpoints.tools.endpoints.complete}`;
    return this.http.post<ApiResponse<any>>(url, nameAcceptedNotes);
  }

  getOfferParameters(): Observable<ServiceType> {
    const url = `${contributorsDashboardEndpoints.adminOffers.baseUrl}${contributorsDashboardEndpoints.adminOffers.endpoints.getParameters}`;
    return this.http.get<ApiResponse<ServiceType>>(url).pipe(map((data) => data.result));
  }

  getUserOffers(resourceId: string): Observable<Offer[]> {
    const id = encodeURIComponent(resourceId);
    const url = `${contributorsDashboardEndpoints.adminOffers.baseUrl}${contributorsDashboardEndpoints.adminOffers.endpoints.getOffers}/${id}`;
    return this.http.get<ApiResponse<Offer[]>>(url).pipe(map((data) => data.result));
  }
}
