import { Injectable } from '@angular/core';
import { ContributorForm } from '../../../interfaces/form-interfaces/contributorForm.interface';
import { ProviderBundle } from '../../../interfaces/provider-interfaces/provider.interface';
import { concatMap, filter, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectContributor } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { HttpClient } from '@angular/common/http';
import { ContributorHelperService } from './helpers/contributor-helper.service';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class ContributorService extends ContributorsDashboardEntityService<ProviderBundle> {
  contributor$: Observable<ProviderBundle | null> = new Observable<ProviderBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private contributorHelperService: ContributorHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'providers');
    this.contributor$ = this.store.select(selectContributor);
  }

  getProvider(providerId: string): Observable<ProviderBundle> {
    return this.get(providerId);
  }

  getProviders(
    from?: number,
    query?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<ProviderBundle>> {
    return this.getEntities(from, query, undefined, status, active);
  }

  saveDraftContributor(contributorModel: ContributorForm): Observable<string> {
    return this.saveEntity(this.saveContributorRequest(contributorModel)).pipe(
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully created ${res.provider?.name} contributor!`
        )
      )
    );
  }

  editContributor(contributorModel: ContributorForm): Observable<string> {
    return this.contributor$.pipe(
      take(1),
      filter((providerBundle: any) => !!providerBundle),
      concatMap((providerBundle: ProviderBundle) =>
        this.editEntity(this.updateContributorRequest(contributorModel, providerBundle))
      ),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully updated ${res.provider?.name} contributor!`
        )
      )
    );
  }

  reviewContributor(
    action: 'approve' | 'reject',
    contributorWorkflowId: string,
    notes: string,
    resubmit: boolean,
    comments: any = null
  ): Observable<string> {
    return this.contributor$.pipe(
      concatMap((contributor) =>
        this.approveRejectEntity(action, contributor!, contributorWorkflowId, notes, resubmit, comments)
      ),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} contributor!`
        )
      )
    );
  }

  saveContributorRequest(contributorModel: ContributorForm): ProviderBundle {
    return {
      provider: this.contributorHelperService.contributorFormModelToContributorInterface(contributorModel),
    } as ProviderBundle;
  }

  updateContributorRequest(contributorModel: ContributorForm, providerBundle: ProviderBundle): ProviderBundle {
    return {
      ...providerBundle,
      provider: {
        ...providerBundle.provider,
        ...this.contributorHelperService.contributorFormModelToContributorInterface(contributorModel),
      },
    };
  }

  offboardContributor(contributorId: string): Observable<string> {
    return this.offboardEntity(contributorId, 'Contributor').pipe(
      take(1),
      filter((res) => !!res),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully offboarded ${contributorId} contributor record!`
        )
      )
    );
  }
}
