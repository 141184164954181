import { environment } from '../../../../../environments/environment';

const baseUrl = `${environment.apiUrl}/action`;
const adminBaseUrl = `${environment.adminApiUrl}/action`;
const adminBaseUrlCatalogue = `${adminBaseUrl}/resource-catalogue`;
const offersUrl = `${environment.apiUrl}/oms/action`;
const toolsApiUrl = `${environment.apiUrl}${environment.toolApiEndpoint}/contributors`;

export const contributorsDashboardEndpoints = {
  vocabulary: {
    baseUrl: `${baseUrl}/vocabulary`,
    endpoints: {
      getByType: '/getByType',
    },
  },
  commonActions: {
    getAll: '/getAll',
    offboard: '/offboard',
  },
  providers: {
    baseUrl: `${adminBaseUrlCatalogue}/providers`,
    offboardUrl: `${adminBaseUrlCatalogue}/contributor`,
  },
  services: {
    baseUrl: `${adminBaseUrlCatalogue}/services`,
    offboardUrl: `${adminBaseUrlCatalogue}/service`,
  },
  datasources: {
    baseUrl: `${adminBaseUrlCatalogue}/datasources`,
    offboardUrl: `${adminBaseUrlCatalogue}/datasource`,
  },
  trainingResources: {
    baseUrl: `${adminBaseUrlCatalogue}/training-resources`,
    offboardUrl: `${adminBaseUrlCatalogue}/training`,
  },
  interoperabilityRecords: {
    baseUrl: `${adminBaseUrlCatalogue}/interoperability-records`,
    offboardUrl: `${adminBaseUrlCatalogue}/ifg`,
  },
  tools: {
    baseUrl: `${toolsApiUrl}`,
    endpoints: {
      complete: '/complete',
      details: '/details',
    },
  },
  adminOffers: {
    baseUrl: `${offersUrl}/admin/offers`,
    endpoints: {
      getParameters: '/parameter',
      getOffers: '/resource',
    },
  },
  catalogueReview: {
    baseUrl: `${adminBaseUrl}/resource-catalogue-review`,
    endpoints: {
      ec: '/ec',
      providers: '/contributor',
      datasources: '/datasource',
      services: '/service',
      trainingResources: '/training',
      interoperabilityRecords: '/interoperability',
      evaluateService: '/evaluate/service',
      tasks: '/tasks',
    },
  },
};
