import { Injectable } from '@angular/core';
import { TrainingResource } from '../../../../interfaces/training-resource-interfaces/training-resource.interface';
import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { DatePipe } from '@angular/common';
import { TrainingResourceForm } from '../../../../interfaces/form-interfaces/trainingResourceForm.interface';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../../shared/interfaces/vocabulary.interface';

@Injectable({
  providedIn: 'root',
})
export class TrainingResourceHelperService {
  trainingResourceDropdowns: VocabularyType[] = [
    VocabularyType.targetUser,
    VocabularyType.trExpertiseLevel,
    VocabularyType.trContentResourceType,
    VocabularyType.trQualification,
    VocabularyType.trDcmiType,
    VocabularyType.language,
    VocabularyType.country,
    VocabularyType.scientificDomain,
    VocabularyType.scientificSubdomain,
  ];

  constructor(private contributorsDashboardHelperService: ContributorsDashboardHelperService) {}

  trainingResourceFormModelToTrainingResourceInterface(
    trainingResourceFormModel: TrainingResourceForm
  ): TrainingResource {
    const trainingResource: TrainingResource = {
      // Basic Subform
      title: trainingResourceFormModel.basic.title,
      authors: [...trainingResourceFormModel.basic.authors],
      url: trainingResourceFormModel.basic.urlToTrainingResource,
      // Detailed And Accessed Subform
      description: trainingResourceFormModel.details.description,
      keywords: [...trainingResourceFormModel.details.keywords],
      license: trainingResourceFormModel.details.license,
      // Learining Subform
      targetGroups: [...trainingResourceFormModel.trainingResourceDetails.targetGroups],
      learningResourceTypes: [...trainingResourceFormModel.trainingResourceDetails.trainingResourceTypes],
      learningOutcomes: [...trainingResourceFormModel.trainingResourceDetails.learningOutcomes],
      expertiseLevel: trainingResourceFormModel.trainingResourceDetails.expertiseLevel,
      contentResourceTypes: [...trainingResourceFormModel.trainingResourceDetails.contentTypes],
      qualifications: [...trainingResourceFormModel.trainingResourceDetails.qualifications],
      duration: trainingResourceFormModel.trainingResourceDetails.duration,
      // Availability Subform
      languages: [...trainingResourceFormModel.availability.languages],
      geographicalAvailabilities: [...trainingResourceFormModel.availability.locations],
      // Classification Subform
      scientificDomains: trainingResourceFormModel.classification.scientificCategorisation.map((domain: any) => {
        return {
          scientificDomain: domain.scientificDomain,
          scientificSubdomain: domain.scientificSubdomain,
        };
      }),
      // Contact Subform
      contact: {
        firstName: trainingResourceFormModel.contact.mainContact.firstName,
        lastName: trainingResourceFormModel.contact.mainContact.lastName,
        email: trainingResourceFormModel.contact.mainContact.email,
        phone: trainingResourceFormModel.contact.mainContact.phone,
        position: trainingResourceFormModel.contact.mainContact.position,
        organisation: trainingResourceFormModel.contact.mainContact.organisation,
      },
    } as TrainingResource;
    return trainingResource;
  }

  trainingResourceInterfaceToTrainingResourceFormModel(
    trainingResource: TrainingResource,
    vocabularies: { [key: string]: Vocabulary[] } | null = null
  ): TrainingResourceForm {
    const trainingResourceFormModel: TrainingResourceForm = {
      basic: {
        title: trainingResource.title,
        authors: trainingResource.authors?.length ? [...trainingResource.authors] : [''],
        urlToTrainingResource: trainingResource.url,
      },
      details: {
        description: trainingResource.description,
        keywords: trainingResource.keywords?.length ? [...trainingResource.keywords] : [''],
        license: trainingResource.license,
      },
      trainingResourceDetails: {
        targetGroups: trainingResource.targetGroups?.length
          ? trainingResource.targetGroups.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.targetUser, val)
            )
          : [''],
        trainingResourceTypes: trainingResource.learningResourceTypes?.length
          ? trainingResource.learningResourceTypes.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.trDcmiType, val)
            )
          : [''],
        learningOutcomes: trainingResource.learningOutcomes?.length ? [...trainingResource.learningOutcomes] : [''],
        expertiseLevel: this.convertValueToLabel(
          vocabularies,
          VocabularyType.trExpertiseLevel,
          trainingResource.expertiseLevel
        ),
        contentTypes: trainingResource.contentResourceTypes?.length
          ? trainingResource.contentResourceTypes.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.trContentResourceType, val)
            )
          : [''],
        qualifications: trainingResource.qualifications?.length
          ? trainingResource.qualifications.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.trQualification, val)
            )
          : [''],
        duration: trainingResource.duration,
      },
      availability: {
        languages: trainingResource.languages?.length
          ? trainingResource.languages.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.language, val)
            )
          : [''],
        locations: trainingResource.geographicalAvailabilities?.length
          ? trainingResource.geographicalAvailabilities.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.country, val)
            )
          : [''],
      },
      classification: {
        scientificCategorisation: trainingResource.scientificDomains?.length
          ? trainingResource.scientificDomains.map((domain: any) => {
              return {
                scientificDomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificDomain,
                  domain.scientificDomain
                ),
                scientificSubdomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificSubdomain,
                  domain.scientificSubdomain
                ),
              };
            })
          : [{ scientificDomain: '', scientificSubdomain: '' }],
      },
      contact: {
        mainContact: {
          firstName: trainingResource.contact?.firstName,
          lastName: trainingResource.contact?.lastName,
          email: trainingResource.contact?.email,
          phone: trainingResource.contact?.phone,
          position: trainingResource.contact?.position,
          organisation: trainingResource.contact?.organisation,
        },
      },
    };

    return trainingResourceFormModel;
  }

  prepareTrainingResourceForPreview() {
    this.trainingResourceDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
