import { Injectable } from '@angular/core';
import { TrainingResourceBundle } from '../../../interfaces/training-resource-interfaces/training-resource.interface';
import { concatMap, filter, map, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { selectTrainingResource } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { HttpClient } from '@angular/common/http';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { TrainingResourceForm } from '../../../interfaces/form-interfaces/trainingResourceForm.interface';
import { TrainingResourceHelperService } from './helpers/training-resource-helper.service';

@Injectable({
  providedIn: 'root',
})
export class TrainingResourceService extends ContributorsDashboardEntityService<TrainingResourceBundle> {
  trainingResource$: Observable<TrainingResourceBundle | null> = new Observable<TrainingResourceBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private trainingResourceHelperService: TrainingResourceHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'trainingResources');
    this.trainingResource$ = this.store.select(selectTrainingResource);
  }

  getTrainingResource(trainingResourceId: string): Observable<TrainingResourceBundle> {
    return this.get(trainingResourceId);
  }

  getTrainingResources(
    contributorId?: string,
    from?: number,
    query?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<TrainingResourceBundle>> {
    return this.getAll(from, query, contributorId, status, active);
  }

  editTrainingResource(trainingResourceModel: TrainingResourceForm): Observable<string> {
    return this.trainingResource$.pipe(
      take(1),
      filter((trainingResourceBundle: any) => !!trainingResourceBundle),
      concatMap((trainingResourceBundle: TrainingResourceBundle) =>
        this.editEntity(this.updateTrainingResourceRequest(trainingResourceModel, trainingResourceBundle))
      ),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully updated ${res.trainingResource?.title} training resource!`
        )
      )
    );
  }

  reviewTrainingResource(
    action: 'approve' | 'reject',
    trainingResourceWorkflowId: string,
    notes: string,
    resubmit: boolean,
    comments: any = null
  ): Observable<string> {
    return this.trainingResource$.pipe(
      concatMap((trainingResource) =>
        this.approveRejectEntity(action, trainingResource!, trainingResourceWorkflowId, notes, resubmit, comments)
      ),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} training resource!`
        )
      )
    );
  }

  toggleTrainingResourceActive(trainingResource: TrainingResourceBundle): Observable<TrainingResourceBundle> {
    trainingResource = { ...trainingResource, active: !trainingResource.active };

    return this.trainingResource$.pipe(
      take(1),
      concatMap(() => this.update(trainingResource)),
      concatMap((res) => {
        if (res) {
          const state = res?.active ? 'Activated' : 'Deactivated';
          const { title } = res.trainingResource;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`The Training Resource "${title}" has been ${state} successfully!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update training resource state.');
        }
      })
    );
  }

  updateTrainingResourceRequest(
    trainingResourceModel: TrainingResourceForm,
    trainingResourceBundle: TrainingResourceBundle
  ): TrainingResourceBundle {
    return {
      ...trainingResourceBundle,
      trainingResource: {
        ...trainingResourceBundle.trainingResource,
        ...this.trainingResourceHelperService.trainingResourceFormModelToTrainingResourceInterface(
          trainingResourceModel
        ),
      },
    };
  }

  offboardTrainingResource(trainingResourceId: string): Observable<string> {
    return this.offboardEntity(trainingResourceId, 'Training Resource').pipe(
      take(1),
      filter((res) => !!res),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully offboarded ${trainingResourceId} Training Resource!`
        )
      )
    );
  }
}
