import { Injectable } from '@angular/core';
import { ServiceBundle } from '../../../interfaces/service-interfaces/service.interface';
import { concatMap, filter, map, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { selectService } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { HttpClient } from '@angular/common/http';
import { ServiceForm } from '../../../interfaces/form-interfaces/serviceForm.interface';
import { ServiceHelperService } from './helpers/service-helper.service';
import { Endpoint, Site } from '../../../interfaces/form-interfaces/additionalsForm.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceService extends ContributorsDashboardEntityService<ServiceBundle> {
  service$: Observable<ServiceBundle | null> = new Observable<ServiceBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private serviceHelperService: ServiceHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'services');
    this.service$ = this.store.select(selectService);
  }

  getService(serviceId: string): Observable<ServiceBundle> {
    return this.get(serviceId);
  }

  getServices(
    contributorId?: string,
    from?: number,
    query?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<ServiceBundle>> {
    return this.getAll(from, query, contributorId, status, active);
  }

  editService(serviceModel: ServiceForm): Observable<string> {
    return this.service$.pipe(
      take(1),
      filter((serviceBundle: any) => !!serviceBundle),
      concatMap((serviceBundle: ServiceBundle) =>
        this.editEntity(this.updateServiceRequet(serviceModel, serviceBundle))
      ),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(`Successfully updated ${res.service?.name} Service!`)
      )
    );
  }

  reviewService(
    action: 'approve' | 'reject',
    serviceWorkflowId: string,
    notes: string,
    resubmit: boolean,
    comments: any = null
  ): Observable<string> {
    return this.service$.pipe(
      concatMap((service) => this.approveRejectEntity(action, service!, serviceWorkflowId, notes, resubmit, comments)),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} service!`
        )
      )
    );
  }

  toggleServiceActive(service: ServiceBundle): Observable<ServiceBundle> {
    service = { ...service, active: !service.active };

    return this.service$.pipe(
      take(1),
      concatMap(() => this.update(service)),
      concatMap((res) => {
        if (res) {
          const state = res.active ? 'Activated' : 'Deactivated';
          const { name } = res.service;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`The Service "${name}" has been ${state} successfully!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update service state.');
        }
      })
    );
  }

  updateServiceRequet(serviceModel: ServiceForm, serviceBundle: ServiceBundle): ServiceBundle {
    return {
      ...serviceBundle,
      sites: this.contributorsDashboardHelperService.cleanArray(
        serviceModel.basic.sites?.map((site: Site) => {
          return {
            name: site.siteName,
            endpoints: this.contributorsDashboardHelperService.cleanArray(
              site.urls.map((url: Endpoint) => {
                return {
                  name: url.name,
                  url: url.endpoint,
                  type: url.type,
                };
              })
            ),
          };
        })!
      ),
      service: {
        ...serviceBundle.service,
        ...this.serviceHelperService.serviceFormModelToServiceInterface(serviceModel),
      },
    };
  }

  offboardService(serviceId: string): Observable<string> {
    return this.offboardEntity(serviceId, 'Service').pipe(
      take(1),
      filter((res) => !!res),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(`Successfully offboarded ${serviceId} service!`)
      )
    );
  }
}
