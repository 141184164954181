import { HttpContextToken } from '@angular/common/http';
import { environment } from '../environments/environment';

interface EndpointDetection {
  baseURL: string;
  endpoint: string;
  type: 'match' | 'prefix';
}

export const authEndpoints: EndpointDetection[] = [
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/catalogue',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: environment.toolApiEndpoint,
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/oms/action/user',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/providers',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/services',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/training-resources',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/interoperability-records',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/datasources',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/tools',
    type: 'prefix',
  },
];

export const excludedEndpointsFromGlobalError: EndpointDetection[] = [
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/credits/projects/',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/admin/profiles/',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/providers/getAll',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/tools/admin/action/v1/contributors',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/resource-catalogue-review/tasks',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/bpm-engine',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/transactions',
    type: 'prefix',
  },
];

// A higher hierarchy exclusion / non-exclusion can be implemented directly into the httpClient service, using the following httpContextToken.
// If set, the above configuration (excludedEndpointsFromGlobalError) is ignored.

export const EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN = new HttpContextToken<boolean | null>(() => null);
