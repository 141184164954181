import { Injectable } from '@angular/core';
import { ToolBundle } from '../../../interfaces/tool-interfaces/tool.interface';
import { concatMap, filter, map, Observable, take } from 'rxjs';
import { selectTool } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { ToolHelperService } from './helpers/tool-helper.service';
import { ToolForm } from '../../../interfaces/form-interfaces/toolForm.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ToolService extends ContributorsDashboardEntityService<ToolBundle> {
  tool$: Observable<ToolBundle | null> = new Observable<ToolBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private toolHelperService: ToolHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'tools');
    this.tool$ = this.store.select(selectTool);
  }

  getToolTosca(toolId: string) {
    return this.getTool(toolId).pipe(map((data) => data.toscaFile));
  }

  editTool(toolModel: ToolForm): Observable<string> {
    return this.tool$.pipe(
      take(1),
      filter((toolBundle: any) => !!toolBundle),
      concatMap((toolBundle: ToolBundle) => this.editEntity(this.updateToolRequest(toolModel, toolBundle))),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(`Successfully updated ${res.tool?.name} tool!`)
      )
    );
  }

  evaluateTool(action: 'approve' | 'reject', toolWorkflowId: string, notes: string) {
    return this.tool$.pipe(
      concatMap((tool) => this.approveRejectEntity(action, tool!, toolWorkflowId, notes)),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} tool!`
        )
      )
    );
  }

  updateToolRequest(toolModel: ToolForm, toolBundle: ToolBundle): ToolBundle {
    return {
      ...toolBundle,
      tool: {
        ...toolBundle.tool,
        ...this.toolHelperService.toolFormModelToToolInterface(toolModel),
      },
    } as ToolBundle;
  }

  toggleToolActive(tool: ToolBundle): Observable<ToolBundle> {
    tool = { ...tool, active: !tool.active };

    return this.tool$.pipe(
      take(1),
      concatMap(() => this.updateTool(tool)),
      concatMap((res) => {
        if (res) {
          const state = res?.active ? 'Activated' : 'Deactivated';
          const { name } = res.tool;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`The Tool "${name}" has been ${state} successfully!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update tool state.');
        }
      })
    );
  }
}
