import { Injectable } from '@angular/core';
import { InteroperabilityRecordBundle } from '../../../interfaces/interoperability-interfaces/interoperability.interface';
import { selectInteroperabilityRecord } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, Observable, take } from 'rxjs';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { HttpClient } from '@angular/common/http';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { InteroperabilityHelperService } from './helpers/interoperability-helper.service';
import { InteroperabilityGuidelineForm } from '../../../interfaces/form-interfaces/interoperabilityGuidelineForm.interface';

@Injectable({
  providedIn: 'root',
})
export class InteroperabilityService extends ContributorsDashboardEntityService<InteroperabilityRecordBundle> {
  interoperabilityGuideline$: Observable<InteroperabilityRecordBundle | null> =
    new Observable<InteroperabilityRecordBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private interoperabilityHelperService: InteroperabilityHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'interoperabilityRecords');
    this.interoperabilityGuideline$ = this.store.select(selectInteroperabilityRecord);
  }

  getInteroperabilityRecord(interoperabilityId: string): Observable<InteroperabilityRecordBundle> {
    return this.get(interoperabilityId);
  }

  getInteroperabilityRecords(
    contributorId?: string,
    from?: number,
    query?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<InteroperabilityRecordBundle>> {
    return this.getAll(from, query, contributorId, status, active);
  }

  editInteroperabilityRecord(interoperabilityGuidelineModel: InteroperabilityGuidelineForm): Observable<string> {
    return this.interoperabilityGuideline$.pipe(
      take(1),
      filter((interoperabilityRecordBundle: any) => !!interoperabilityRecordBundle),
      concatMap((interoperabilityRecordBundle: InteroperabilityRecordBundle) =>
        this.editEntity(
          this.updateInteroperabilityGuidelineRequest(interoperabilityGuidelineModel, interoperabilityRecordBundle)
        )
      ),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully updated ${res.interoperabilityRecord?.title} Interoperability Guideline!`
        )
      )
    );
  }

  reviewInteroperabilityRecord(
    action: 'approve' | 'reject',
    interoperabilityWorkflowId: string,
    notes: string,
    resubmit: boolean,
    comments: any = null
  ): Observable<string> {
    return this.interoperabilityGuideline$.pipe(
      concatMap((interoperabilityGuideline) =>
        this.approveRejectEntity(
          action,
          interoperabilityGuideline!,
          interoperabilityWorkflowId,
          notes,
          resubmit,
          comments
        )
      ),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} interoperability guideline!`
        )
      )
    );
  }

  toggleInteroperabilityGuidelineActive(
    interoperabilityGuideline: InteroperabilityRecordBundle
  ): Observable<InteroperabilityRecordBundle> {
    interoperabilityGuideline = { ...interoperabilityGuideline, active: !interoperabilityGuideline.active };

    return this.interoperabilityGuideline$.pipe(
      take(1),
      concatMap(() => this.update(interoperabilityGuideline)),
      concatMap((res) => {
        if (res) {
          const state = res?.active ? 'Activated' : 'Deactivated';
          const { title } = res.interoperabilityRecord;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`The Interoperability "${title}" has been ${state} successfully!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update interoperability state.');
        }
      })
    );
  }

  updateInteroperabilityGuidelineRequest(
    interoperabilityGuidelineModel: InteroperabilityGuidelineForm,
    interoperabilityRecordBundle: InteroperabilityRecordBundle
  ): InteroperabilityRecordBundle {
    return {
      ...interoperabilityRecordBundle,
      interoperabilityRecord: {
        ...interoperabilityRecordBundle.interoperabilityRecord!,
        ...this.interoperabilityHelperService.interoperabilityFormModelToInteroperabilityInterface(
          interoperabilityGuidelineModel
        ),
      },
    } as InteroperabilityRecordBundle;
  }

  offboardInteroperabilityGuideline(interoperabilityGuidelineId: string): Observable<string> {
    return this.offboardEntity(interoperabilityGuidelineId, 'Interoperability Guideline').pipe(
      take(1),
      filter((res) => !!res),
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully offboarded ${interoperabilityGuidelineId} Interoperability Guideline!`
        )
      )
    );
  }
}
