export interface AlternativeIdentifier {
  type: string;
  value: string;
}

export interface MultimediaPair {
  multimediaURL: string;
  multimediaName: string;
}

export interface ServiceProviderDomain {
  scientificDomain: string;
  scientificSubdomain: string;
}

export interface Identifiers {
  originalId: string;
}

export interface MigrationStatus {
  comments: string[];
  modified: string;
  migrationDate: string;
  resolutionDate: string;
  modelVersion: string;
}

export interface Metadata {
  registeredBy: string;
  registeredAt: string;
  modifiedBy: string;
  modifiedAt: string;
  terms: string[];
  published: boolean;
}

export interface LoggingInfo {
  date: string;
  userEmail: string;
  userFullName: string;
  userRole: string;
  type: Type;
  comment: string | null;
  actionType: ActionType;
}

export interface User {
  id: string;
  email: string;
  name: string;
  surname: string;
}

export interface UseCasesPair {
  useCaseURL: string;
  useCaseName: string;
}

export interface ResourceExtras {
  eoscIFGuidelines: EOSCIFGuidelines[];
}

export interface EOSCIFGuidelines {
  pid: string;
  label: string;
  url: string;
  semanticRelationship: string;
}

export interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  organisation: string;
}

export interface ServiceMainContact extends Contact {}

export interface NameAcceptedNotes {
  name: string;
  notes: string;
  accepted: boolean;
  resubmit?: boolean;
}

export enum ActionType {
  // Onboard
  REGISTERED = 'registered',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  // Update
  UPDATED = 'updated',
  UPDATED_VERSION = 'updated version',
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
  SUSPENDED = 'suspended',
  UNSUSPENDED = 'unsuspended',
  // Audit
  VALID = 'valid',
  INVALID = 'invalid',
  // Draft
  CREATED = 'drafted',
  // Resource change provider
  MOVED = 'moved',
}

export enum Type {
  // Onboard
  ONBOARD = 'onboard',
  // Update
  UPDATE = 'update',
  // Audit
  AUDIT = 'audit',
  // Draft
  DRAFT = 'draft',
  // Resource change provider
  MOVE = 'move',
}
