import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, from, map, switchMap, take } from 'rxjs';
import { ContributorsDashboardActions } from '../../../../../../../core/state/contributors-dashboard/contributors-dashboard.actions';
import { selectVocabularies } from '../../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { VocabularyDropdown, Vocabulary } from '../../../../../shared/interfaces/vocabulary.interface';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ContributorsDashboardHelperService {
  constructor(private store: Store, private dialog: MatDialog) {}

  getVocabularyType(vocabularyType: VocabularyType): Observable<VocabularyDropdown[]> {
    this.loadVocabulary(vocabularyType);
    const vocabulary$: Observable<VocabularyDropdown[]> = this.store.select(selectVocabularies).pipe(
      map((vocabularies) => {
        return vocabularies[vocabularyType]?.map((x) => {
          return { value: x.id, label: x.name, parentId: x.parentId };
        });
      })
    );
    return vocabulary$;
  }

  loadVocabulary(vocabularyType: VocabularyType) {
    this.store.dispatch(ContributorsDashboardActions.loadVocabularies({ vocabularyType }));
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    if (!vocabularies) return value;
    return vocabularies[vocabularyType]?.find((v) => v.id === value)?.name!;
  }

  cleanArray(input: any[]): any[] {
    if (Array.isArray(input)) {
      if (input.length === 1 && input[0] === '') return [];

      if (
        input.every(
          (item) => typeof item === 'object' && item !== null && Object.values(item).every((value) => value === '')
        )
      )
        return [];
    }
    return input;
  }

  onOpenGenericModal(text: string, type: 'confirmation' | 'information' = 'information'): Observable<string> {
    const buttonElement = document.activeElement as HTMLElement;
    buttonElement.blur(); // Remove focus from the button

    const modalImport$ = from(
      import('../../../../modals/contributor-generic-modal/contributor-generic-modal.component')
    );

    return modalImport$.pipe(
      take(1),
      switchMap((module) => {
        const dialogRef = this.dialog.open(module.ContributorGenericModalComponent, {
          data: { text, type },
          disableClose: true,
        });

        return dialogRef.afterClosed().pipe(take(1));
      })
    );
  }

  commentReviewMessage(entity: string) {
    return `You can place review comments for the ${entity} to see at each form's field by clicking on the icon next to each field and filling in the designated field.`;
  }
}
