import { Injectable } from '@angular/core';
import { ProviderBundle } from '../../interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../interfaces/service-interfaces/service.interface';
import { TrainingResourceBundle } from '../../interfaces/training-resource-interfaces/training-resource.interface';
import { InteroperabilityRecordBundle } from '../../interfaces/interoperability-interfaces/interoperability.interface';
import { DatasourceFull } from '../../interfaces/datasource-interfaces/datasource.interface';
import { ContributorsDashboardService } from './http/contributors-dashboard.service';
import { HttpClient } from '@angular/common/http';
import { concatMap, filter, Observable, of, take } from 'rxjs';
import { UserProfile } from '../../../../../core/interfaces/auth';
import { Store } from '@ngrx/store';
import { selectProfile } from '../../../../../core/state/auth/auth.selectors';
import { ContributorsDashboardHelperService } from './utils/helpers/contributors-dashboard-helper.service';
import { ActionType, Type, Metadata, LoggingInfo, NameAcceptedNotes } from '../../interfaces/additionals.interface';
import { ContributorsApiResponse } from '../../interfaces/contrbutorsApiResponse.interface';
import { ToolBundle } from '../../interfaces/tool-interfaces/tool.interface';
import { EntityForReview } from '../../interfaces/review-entity-interfaces/review-entity.interface';
import { ContributorRole } from '../../interfaces/contributorRole.interface';
import { selectContributorRole } from '../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';

@Injectable({
  providedIn: 'root',
})
export abstract class ContributorsDashboardEntityService<
  T extends
    | EntityForReview
    | ProviderBundle
    | ServiceBundle
    | DatasourceFull
    | TrainingResourceBundle
    | InteroperabilityRecordBundle
    | ToolBundle
> extends ContributorsDashboardService<T> {
  profile$: Observable<UserProfile | null> = new Observable<UserProfile | null>();
  contributorRole$: Observable<ContributorRole | null> = new Observable<ContributorRole | null>();
  entityType: string = '';

  constructor(
    protected store: Store,
    protected contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    entity: string
  ) {
    super(http, entity);
    this.entityType = entity;
    this.profile$ = this.store.select(selectProfile);
    this.contributorRole$ = this.store.select(selectContributorRole);
  }

  getEntities(
    from?: number,
    query?: string,
    contributorId?: string,
    status?: string,
    active?: boolean
  ): Observable<ContributorsApiResponse<T>> {
    return this.getAll(from, query, contributorId, status, active);
  }

  saveEntity(entity: T): Observable<T> {
    return this.contributorsDashboardHelperService
      .onOpenGenericModal('You are going to Save as Draft. Are you sure?', 'confirmation')
      .pipe(
        take(1),
        filter((action) => !!action),
        concatMap(() => this.profile$),
        concatMap((profile) => this.createEntity(entity, profile!))
      );
  }

  editEntity(entity: T, message: string = 'You are goint to Update. Are you sure?') {
    return this.contributorsDashboardHelperService.onOpenGenericModal(message, 'confirmation').pipe(
      take(1),
      filter((action) => !!action),
      concatMap(() => this.profile$),
      concatMap((profile) => this.updateEntity(entity, profile!))
    );
  }

  createEntity(entity: T, profile: UserProfile, draft: boolean = true): Observable<T> {
    const createEntityRequest: T = this.getCreateEntityRequest(entity, profile, draft);
    return this.create(createEntityRequest);
  }

  updateEntity(entity: T, profile: UserProfile): Observable<T> {
    const updateEntityRequest: T = this.getUpdateEntityRequest(entity, profile);
    return this.entityType == 'tools'
      ? this.updateTool(updateEntityRequest as ToolBundle)
      : this.update(updateEntityRequest);
  }

  approveRejectEntity(
    action: 'approve' | 'reject',
    entity: T,
    entityWorkflowId: string,
    notes: string,
    resubmit: boolean = false,
    comments: any = null
  ): Observable<any> {
    let message: string = `You are going to ${action}. Are you sure?`;
    if (resubmit && action == 'reject')
      message = 'You are going to reject this submission wht Request for Improvements, Are you sure?';

    return this.contributorsDashboardHelperService.onOpenGenericModal(message, 'confirmation').pipe(
      take(1),
      filter((action) => !!action),
      concatMap(() => this.profile$),
      concatMap((profile) => {
        if (action == 'reject' && this.entityType != 'tools')
          return this.updateCommentEntity(entity, profile!, comments);
        else return of('accept');
      }),
      concatMap(() => this.profile$),
      concatMap((profile) => this.reviewEntity(action, notes, resubmit, profile!, entityWorkflowId)),
      filter((res) => res.success)
    );
  }

  updateCommentEntity(entity: T, profile: UserProfile, comments: any = null): Observable<T> {
    const updateCommentEntityRequest: T = this.getUpdateCommentEntityRequest(entity, profile, comments);
    return this.update(updateCommentEntityRequest);
  }

  updateInternalCommentEntity(entity: T, internalComments: string): Observable<T> {
    const updateCommentEntityRequest: T = this.getUpdateInternalCommentEntityRequest(entity, internalComments);
    if (this.entityType == 'tools') return this.updateTool(updateCommentEntityRequest as ToolBundle);
    else return this.update(updateCommentEntityRequest);
  }

  reviewEntity(action: 'approve' | 'reject', notes: string, resubmit: boolean, profile: UserProfile, entityId: string) {
    return this.contributorRole$.pipe(
      take(1),
      concatMap((contributorRole) => {
        const profileType = contributorRole?.eot ? 'eot' : 'ec';
        let nameAcceptedNotes: NameAcceptedNotes = {
          name: profile?.name!,
          notes: JSON.stringify({ notes: { [profileType]: notes } }), // Stringify notes with dynamic profile type ('eot' or 'ec') for easy display based on role.
          accepted: action == 'approve',
        };
        if (this.entityType == 'tools') return this.reviewTool(nameAcceptedNotes, entityId);
        else {
          nameAcceptedNotes = { ...nameAcceptedNotes, resubmit };
          return this.review(profileType, nameAcceptedNotes, entityId);
        }
      })
    );
  }

  offboardEntity(entityId: string, entityName: string): Observable<T> {
    return this.contributorsDashboardHelperService
      .onOpenGenericModal(`You are going to Offboard a ${entityName}. Are you sure?`, 'confirmation')
      .pipe(
        take(1),
        filter((action) => !!action),
        concatMap(() => {
          return this.offboard(entityId);
        }),
        take(1)
      );
  }

  getCreateEntityRequest(entity: T, profile: UserProfile, draft: boolean): T {
    if (this.entityType == 'datasources')
      return {
        datasource: {
          ...(entity as DatasourceFull).datasource,
          metadata: this.getMetadata(null, profile),
          active: false,
          draft,
          suspended: false,
          loggingInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED)[0],
        },
        service: {
          ...(entity as DatasourceFull).service,
          metadata: this.getMetadata(null, profile),
          active: false,
          draft,
          suspended: false,
          loggingInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED)[0],
        },
      } as T;
    else
      return {
        ...entity,
        metadata: this.getMetadata(null, profile),
        active: false,
        draft,
        suspended: false,
        loggingInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED),
        latestUpdateInfo: this.getLoggingInfo(null, profile, Type.DRAFT, ActionType.CREATED)[0],
      };
  }

  getUpdateEntityRequest(entity: T, profile: UserProfile): T {
    if (this.entityType == 'datasources')
      return {
        datasource: {
          ...(entity as DatasourceFull).datasource,
          metadata: this.getMetadata(null, profile),
          loggingInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED)[0],
        },
        service: {
          ...(entity as DatasourceFull).service,
          metadata: this.getMetadata(null, profile),
          loggingInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED)[0],
        },
      } as T;
    else
      return {
        ...entity,
        metadata: this.getMetadata(null, profile),
        loggingInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED),
        latestUpdateInfo: this.getLoggingInfo(null, profile, Type.UPDATE, ActionType.UPDATED)[0],
      };
  }

  getUpdateCommentEntityRequest(entity: T, profile: UserProfile, comments: any = null): T {
    if (this.entityType === 'datasources')
      return {
        datasource: {
          ...(entity as DatasourceFull).datasource,
          metadata: this.getMetadata(entity, profile),
          suspended: false,
          loggingInfo: this.getLoggingInfo(entity, profile, Type.AUDIT, ActionType.REJECTED, comments),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.AUDIT, ActionType.REJECTED)[0],
        },
        service: {
          ...(entity as DatasourceFull).service,
          metadata: this.getMetadata(entity, profile),
          suspended: false,
          loggingInfo: this.getLoggingInfo(entity, profile, Type.AUDIT, ActionType.REJECTED, comments),
          latestUpdateInfo: this.getLoggingInfo(null, profile, Type.AUDIT, ActionType.REJECTED)[0],
        },
      } as T;
    else
      return {
        ...entity,
        metadata: this.getMetadata(entity, profile),
        suspended: false,
        loggingInfo: this.getLoggingInfo(entity, profile, Type.AUDIT, ActionType.REJECTED, comments),
        latestUpdateInfo: this.getLoggingInfo(null, profile, Type.AUDIT, ActionType.REJECTED)[0],
      };
  }

  getUpdateInternalCommentEntityRequest(entity: T, internalComments: string) {
    if (this.entityType === 'datasources')
      return {
        datasource: {
          ...(entity as DatasourceFull).datasource,
          internalComments,
        },
        service: {
          ...(entity as DatasourceFull).service,
          internalComments,
        },
      } as T;
    else
      return {
        ...entity,
        internalComments,
      };
  }

  getMetadata(entity: T | null, profile: UserProfile): Metadata {
    if (entity)
      if (this.entityType == 'datasources')
        return {
          ...(entity as DatasourceFull).datasource.metadata,
          modifiedBy: profile.name,
          modifiedAt: new Date().getTime().toString(),
        };
      else
        return {
          ...(entity as ProviderBundle | ServiceBundle | TrainingResourceBundle | InteroperabilityRecordBundle)
            .metadata,
          modifiedBy: profile.name,
          modifiedAt: new Date().getTime().toString(),
        } as Metadata;
    else
      return {
        registeredBy: profile.name,
        registeredAt: new Date().getTime().toString(),
        modifiedBy: profile.name,
        modifiedAt: new Date().getTime().toString(),
        terms: [profile.email],
        published: false,
      } as Metadata;
  }

  getLoggingInfo(
    entity: T | null,
    profile: UserProfile,
    type: Type,
    actionType: ActionType,
    comments: any = null
  ): LoggingInfo[] {
    if (entity)
      if (this.entityType == 'datasources')
        return [
          ...(entity as DatasourceFull).datasource.loggingInfo!,
          {
            date: new Date().getTime().toString(),
            userEmail: profile.email,
            userFullName: profile.name,
            userRole: 'provider',
            type,
            comment: comments ? JSON.stringify(comments) : null,
            actionType,
          },
        ];
      else
        return [
          ...(entity as ProviderBundle | ServiceBundle | TrainingResourceBundle | InteroperabilityRecordBundle)
            .loggingInfo!,
          {
            date: new Date().getTime().toString(),
            userEmail: profile.email,
            userFullName: profile.name,
            userRole: 'provider',
            type,
            comment: comments ? JSON.stringify(comments) : null,
            actionType,
          },
        ] as LoggingInfo[];
    else
      return [
        {
          date: new Date().getTime().toString(),
          userEmail: profile.email,
          userFullName: profile.name,
          userRole: 'provider',
          type,
          comment: null,
          actionType,
        },
      ] as LoggingInfo[];
  }
}
