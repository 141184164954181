import { Injectable } from '@angular/core';
import { Datasource, DatasourceFull } from '../../../../interfaces/datasource-interfaces/datasource.interface';
import { DatasourceForm } from '../../../../interfaces/form-interfaces/datasourceForm.interface';
import { ServiceHelperService } from './service-helper.service';
import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary, booleanVocabulary } from '../../../../../shared/interfaces/vocabulary.interface';
import { Service } from '../../../../interfaces/service-interfaces/service.interface';
import { OAIPmhInfo } from '../../../../interfaces/datasource-interfaces/additionals-datasource.interface';

@Injectable({
  providedIn: 'root',
})
export class DatasourceHelperService {
  datasourceDropdowns: VocabularyType[] = [
    VocabularyType.dsType,
    VocabularyType.dsJurisdiction,
    VocabularyType.dsOAIFormats,
    VocabularyType.dsOAICompatibility,
    VocabularyType.dsResearchEntityType,
    VocabularyType.dsClassification,
    VocabularyType.dsCoarAccessRight,
    VocabularyType.dsPersistentIdentityScheme,
    ...this.serviceHelperService.serviceDropdowns,
  ];

  constructor(
    private contributorsDashboardHelperService: ContributorsDashboardHelperService,
    private serviceHelperService: ServiceHelperService
  ) {}

  datasourceFormModelToDatasourceServiceInterface(datasourceFormModel: DatasourceForm): {
    datasource: Datasource;
    service: Service;
    oaiPmhInfo: OAIPmhInfo;
    datasourceType: string;
  } {
    const service: Service = {
      ...this.serviceHelperService.serviceFormModelToServiceInterface(datasourceFormModel),
      targetUsers: ['target_user-other'],
      orderType: 'order_type-other',
      trl: 'trl-9',
      categories: [{ category: 'category-other-other', subcategory: 'subcategory-other-other-other' }],
    };

    const datasource: Datasource = {
      // Datasource Information subform
      submissionPolicyURL: datasourceFormModel.datasourceInformation.submissionPolicyURL,
      preservationPolicyURL: datasourceFormModel.datasourceInformation.preservationPolicyURL,
      versionControl: datasourceFormModel.datasourceInformation.versionControl,
      persistentIdentitySystems: datasourceFormModel.datasourceInformation.persistentIdentitySystems.map((pis) => {
        return {
          persistentIdentityEntityType: pis.persistentIdentityEntityType,
          persistentIdentityEntityTypeSchemes: [...pis.persistentIdentityEntityTypeSchemes],
        };
      }),
      // Datasource Metadata subform
      jurisdiction: datasourceFormModel.datasourceMetadata.jurisdiction,
      datasourceClassification: datasourceFormModel.datasourceMetadata.datasourceClassification,
      thematic: datasourceFormModel.datasourceMetadata.thematic,
      researchProductAccessPolicies: [...datasourceFormModel.datasourceMetadata.researchProductAccessPolicies],
      researchProductMetadataLicensing: {
        researchProductMetadataLicenseName:
          datasourceFormModel.datasourceMetadata.researchProductMetadataLicensing.researchProductMetadataLicenseName,
        researchProductMetadataLicenseURL:
          datasourceFormModel.datasourceMetadata.researchProductMetadataLicensing.researchProductMetadataLicenseURL,
      },
      researchProductMetadataAccessPolicies: [...datasourceFormModel.datasourceMetadata.researchProductAccessPolicies],
      harvestable: datasourceFormModel.datasourceMetadata.harvestable,
    } as Datasource;

    const oaiPmhInfo: OAIPmhInfo = {
      protocol: datasourceFormModel.datasourceMetadata?.protocol,
      baseUrl: datasourceFormModel.datasourceMetadata?.baseUrl,
      sets: this.contributorsDashboardHelperService.cleanArray(datasourceFormModel.datasourceMetadata.oaiSets),
      format: datasourceFormModel.datasourceMetadata?.format,
      compatibility: datasourceFormModel.datasourceMetadata?.compatibility,
      openAIRECompliance: datasourceFormModel.datasourceMetadata?.openAIREComplianceGuidelines,
      repositoryIdentifier: {
        value: datasourceFormModel.datasourceMetadata?.repositoryIdentifier,
        type: datasourceFormModel.datasourceMetadata?.repositoryIdentifierType,
      },
    } as OAIPmhInfo;

    const datasourceType = datasourceFormModel.datasourceInformation?.datasourceType;

    return { datasource, service, oaiPmhInfo, datasourceType };
  }

  datasourceInterfaceToDatasourceFormModel(
    datasource: DatasourceFull,
    vocabularies: { [key: string]: Vocabulary[] } | null = null
  ): DatasourceForm {
    const datasourceFormModel: DatasourceForm = {
      ...this.serviceHelperService.serviceInterfaceToServiceFormModel(datasource?.service, vocabularies),
      datasourceInformation: {
        datasourceType: datasource?.datasource?.datasourceType,
        submissionPolicyURL: datasource?.datasource?.datasource?.submissionPolicyURL,
        preservationPolicyURL: datasource?.datasource?.datasource?.preservationPolicyURL,
        versionControl: vocabularies
          ? booleanVocabulary.find((x) => x.value == datasource?.datasource?.datasource?.versionControl)?.label
          : datasource?.datasource?.datasource?.versionControl,
        persistentIdentitySystems: datasource.datasource?.datasource?.persistentIdentitySystems?.length
          ? datasource.datasource?.datasource?.persistentIdentitySystems.map((pis) => {
              return {
                persistentIdentityEntityType: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.dsResearchEntityType,
                  pis.persistentIdentityEntityType
                ),
                persistentIdentityEntityTypeSchemes: pis.persistentIdentityEntityTypeSchemes.length
                  ? this.convertValueToLabel(
                      vocabularies,
                      VocabularyType.dsPersistentIdentityScheme,
                      pis.persistentIdentityEntityTypeSchemes[0]
                    )
                  : '',
              };
            })
          : [{ persistentIdentityEntityType: '', persistentIdentityEntityTypeSchemes: [''] }],
      },
      datasourceMetadata: {
        jurisdiction: this.convertValueToLabel(
          vocabularies,
          VocabularyType.dsJurisdiction,
          datasource?.datasource?.datasource?.jurisdiction
        ),
        datasourceClassification: this.convertValueToLabel(
          vocabularies,
          VocabularyType.dsClassification,
          datasource?.datasource?.datasource?.datasourceClassification
        ),
        thematic: vocabularies
          ? booleanVocabulary.find((x) => x.value == datasource?.datasource?.datasource?.thematic)?.label
          : datasource?.datasource?.datasource?.thematic,
        researchProductAccessPolicies: datasource.datasource?.datasource?.researchProductAccessPolicies?.length
          ? [...datasource.datasource?.datasource?.researchProductAccessPolicies]
          : [''],
        researchProductMetadataLicensing: {
          researchProductMetadataLicenseName:
            datasource?.datasource?.datasource?.researchProductMetadataLicensing?.researchProductMetadataLicenseName,
          researchProductMetadataLicenseURL:
            datasource?.datasource?.datasource?.researchProductMetadataLicensing?.researchProductMetadataLicenseURL,
        },
        researchProductMetadataAccessPolicies:
          datasource?.datasource?.datasource?.researchProductMetadataAccessPolicies.map((val) =>
            this.convertValueToLabel(vocabularies, VocabularyType.dsCoarAccessRight, val)
          ),
        harvestable: vocabularies
          ? booleanVocabulary.find((x) => x.value == datasource?.datasource?.datasource?.harvestable)?.label
          : datasource?.datasource?.datasource?.harvestable,
        protocol: datasource.datasource?.oaiPmhInfo?.protocol,
        baseUrl: datasource.datasource?.oaiPmhInfo?.baseUrl,
        oaiSets: datasource.datasource?.oaiPmhInfo?.sets?.length ? [...datasource.datasource?.oaiPmhInfo?.sets] : [''],
        format: datasource.datasource?.oaiPmhInfo?.format,
        compatibility: this.convertValueToLabel(
          vocabularies,
          VocabularyType.dsOAICompatibility,
          datasource.datasource?.oaiPmhInfo?.compatibility
        ),
        openAIREComplianceGuidelines: vocabularies
          ? booleanVocabulary.find((x) => x.value == datasource?.datasource?.oaiPmhInfo?.openAIRECompliance)?.label
          : datasource?.datasource?.oaiPmhInfo?.openAIRECompliance,
        repositoryIdentifier: datasource.datasource?.oaiPmhInfo?.repositoryIdentifier?.value,
        repositoryIdentifierType: datasource.datasource?.oaiPmhInfo?.repositoryIdentifier?.type,
      },
    } as DatasourceForm;

    return datasourceFormModel;
  }

  prepareDatasourceForPreview() {
    this.datasourceDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
