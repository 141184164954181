import { Injectable } from '@angular/core';
import { MultimediaPair, ServiceProviderDomain } from '../../../../interfaces/additionals.interface';
import { Provider } from '../../../../interfaces/provider-interfaces/provider.interface';
import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { ContributorForm } from '../../../../interfaces/form-interfaces/contributorForm.interface';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary, booleanVocabulary } from '../../../../../shared/interfaces/vocabulary.interface';
import { User } from '../../../../interfaces/form-interfaces/additionalsForm.interface';

@Injectable({
  providedIn: 'root',
})
export class ContributorHelperService {
  contributorDropdowns: VocabularyType[] = [
    VocabularyType.providerLegalStatus,
    VocabularyType.scientificDomain,
    VocabularyType.scientificSubdomain,
    VocabularyType.country,
    VocabularyType.providerEsfriDomain,
    VocabularyType.providerEsfriType,
    VocabularyType.providerMerilScientificDomain,
    VocabularyType.providerMerilScientificSubdomain,
    VocabularyType.providerAreaOfActivity,
    VocabularyType.providerSocietalGrandChallenge,
  ];
  constructor(private contributorsDashboardHelperService: ContributorsDashboardHelperService) {}

  contributorFormModelToContributorInterface(contributorFormModel: ContributorForm): Provider {
    const provider: Provider = {
      // Basic Subform
      abbreviation: contributorFormModel.basic.abbreviation,
      name: contributorFormModel.basic.name,
      legalEntity: contributorFormModel.basic.legalEntity,
      legalStatus: contributorFormModel.basic.legalStatus,
      website: contributorFormModel.basic.website,
      // Profile Subform
      description: contributorFormModel.profile.description,
      logo: contributorFormModel.profile.logo,
      // Classification Subform
      scientificDomains: this.contributorsDashboardHelperService.cleanArray(
        contributorFormModel.classification.scientificCategorisation.map((domain: any) => {
          return {
            scientificDomain: domain.scientificDomain,
            scientificSubdomain: domain.scientificSubdomain,
          };
        })
      ),
      tags: this.contributorsDashboardHelperService.cleanArray(contributorFormModel.classification.tags),
      // Location Subform
      location: {
        streetNameAndNumber: contributorFormModel.location.address,
        postalCode: contributorFormModel.location.postalCode,
        city: contributorFormModel.location.city,
        region: contributorFormModel.location.region,
        country: contributorFormModel.location.country,
      },
      // Contact Subform
      mainContact: {
        firstName: contributorFormModel.contact.mainContact.firstName,
        lastName: contributorFormModel.contact.mainContact.lastName,
        email: contributorFormModel.contact.mainContact.email,
        phone: contributorFormModel.contact.mainContact.phone,
        position: contributorFormModel.contact.mainContact.position,
      },
      // Other Subform
      esfriDomains: this.contributorsDashboardHelperService.cleanArray(contributorFormModel.other.esfriDomains),
      esfriType: contributorFormModel.other.esfriType,
      merilScientificDomains: this.contributorsDashboardHelperService.cleanArray(
        contributorFormModel.other.merilScientificCategorisation.map((category: any) => {
          return {
            merilScientificDomain: category.merilScientificDomain,
            merilScientificSubdomain: category.merilScientificSubdomain,
          };
        })
      ),
      certifications: this.contributorsDashboardHelperService.cleanArray(contributorFormModel.other.certifications),
      areasOfActivity: this.contributorsDashboardHelperService.cleanArray(contributorFormModel.other.areasOfActivity),
      societalGrandChallenges: this.contributorsDashboardHelperService.cleanArray(
        contributorFormModel.other.societalGrandChallenges
      ),
      // Users Subform
      users: [
        {
          name: (contributorFormModel.users.users as User).firstName,
          surname: (contributorFormModel.users.users as User).lastName,
          email: (contributorFormModel.users.users as User).email,
        },
      ],
    } as Provider;
    return provider;
  }

  contributorInterfaceToContributorFormModel(
    contributor: Provider,
    vocabularies: { [key: string]: Vocabulary[] } | null = null
  ): ContributorForm {
    const contributorFormModel: ContributorForm = {
      basic: {
        name: contributor.name,
        abbreviation: contributor.abbreviation,
        legalEntity: vocabularies
          ? booleanVocabulary.find((x) => x.value === contributor.legalEntity)?.label
          : contributor.legalEntity,
        legalStatus: contributor.legalStatus
          ? this.convertValueToLabel(vocabularies, VocabularyType.providerLegalStatus, contributor.legalStatus)
          : null,
        website: contributor.website,
      },
      profile: {
        description: contributor.description,
        logo: contributor.logo,
      },
      classification: {
        scientificCategorisation: contributor.scientificDomains?.length
          ? contributor.scientificDomains?.map((domain: ServiceProviderDomain) => {
              return {
                scientificDomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificDomain,
                  domain.scientificDomain
                ),
                scientificSubdomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.scientificSubdomain,
                  domain.scientificSubdomain
                ),
              };
            })
          : [{ scientificDomain: '', scientificSubdomain: '' }],
        tags: contributor.tags?.length ? [...contributor.tags] : [''],
      },
      location: {
        address: contributor.location?.streetNameAndNumber,
        postalCode: contributor.location?.postalCode,
        city: contributor.location?.city,
        region: contributor.location?.region,
        country: contributor.location?.country
          ? this.convertValueToLabel(vocabularies, VocabularyType.country, contributor.location.country)
          : '',
      },
      contact: {
        mainContact: {
          firstName: contributor.mainContact.firstName,
          lastName: contributor.mainContact.lastName,
          email: contributor.mainContact.email,
          phone: contributor.mainContact.phone,
          position: contributor.mainContact.position,
        },
      },
      other: {
        esfriDomains: contributor.esfriDomains?.length
          ? contributor.esfriDomains?.map((domain) =>
              this.convertValueToLabel(vocabularies, VocabularyType.providerEsfriDomain, domain)
            )
          : [''],
        esfriType: this.convertValueToLabel(vocabularies, VocabularyType.providerEsfriType, contributor.esfriType),
        merilScientificCategorisation: contributor.merilScientificDomains?.length
          ? contributor.merilScientificDomains?.map((category: any) => {
              return {
                merilScientificDomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.providerMerilScientificDomain,
                  category.merilScientificDomain
                ),
                merilScientificSubdomain: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.providerMerilScientificSubdomain,
                  category.merilScientificSubdomain
                ),
              };
            })
          : [{ merilScientificDomain: '', merilScientificSubdomain: '' }],
        certifications: contributor.certifications?.length ? [...contributor.certifications] : [''],
        areasOfActivity: contributor.areasOfActivity?.length
          ? contributor.areasOfActivity?.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.providerAreaOfActivity, val)
            )
          : [''],
        societalGrandChallenges: contributor.societalGrandChallenges?.length
          ? contributor.societalGrandChallenges!.map((val) =>
              this.convertValueToLabel(vocabularies, VocabularyType.providerSocietalGrandChallenge, val)
            )
          : [''],
      },
      users: {
        users: contributor.users?.map((user) => {
          return {
            firstName: user.name,
            lastName: user.surname,
            email: user.email,
          };
        }),
      },
    };
    return contributorFormModel;
  }

  prepareContributorForPreview() {
    this.contributorDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
