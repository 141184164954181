import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { ServiceType } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/serviceType.interface';
import { Offer } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/offer.interface';
import { VocabularyType } from '../../../features/dashboard/shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../features/dashboard/shared/interfaces/vocabulary.interface';
import { DatasourceFull } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { ToolBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/tool-interfaces/tool.interface';
import { EntityForReview } from '../../../features/dashboard/contributors-dashboard/interfaces/review-entity-interfaces/review-entity.interface';
import { ContributorRole } from '../../../features/dashboard/contributors-dashboard/interfaces/contributorRole.interface';

export const ContributorsDashboardActions = createActionGroup({
  source: 'ContributorsDashboard',
  events: {
    IncrementLoading: emptyProps(),
    DecrementLoading: emptyProps(),
    // For GENERAL
    'Load Contributor Mode': emptyProps(),
    'Update Contributor Mode': props<{ value: boolean }>(),
    'Clear Contributor Mode': emptyProps(),

    'Load Contributor Role': emptyProps(),
    'Load Contributor Role Success': props<{ value: ContributorRole }>(),
    'Clear Contributor Role': emptyProps(),

    'Load Entity For Review': props<{ businessKey: string }>(),
    'Load Entity For Review Success': props<{ entityForReview: EntityForReview | null }>(),
    'Update Entity For Review': props<{ value: EntityForReview }>(),
    'Clear Entity For Review': emptyProps(),

    'Load Entities For Review': props<{ from: number; query: string; taskType?: string[] }>(),
    'Load Entities For Review Success': props<{ results: EntityForReview[]; total: number; from: number }>(),
    'Clear Entities For Review': emptyProps(),

    'Load Contributor': props<{ resourceId: string }>(),
    'Load Contributor Success': props<{ contributor: any }>(),
    'Update Contributor': props<{ value: any }>(),
    'Clear Contributor': emptyProps(),

    'Load Contributors': props<{ from: number; query: string; status?: string; active?: boolean }>(),
    'Load Contributors Success': props<{ results: ProviderBundle[]; total: number; from: number }>(),
    'Clear Contributors': emptyProps(),

    'Load Service': props<{ resourceId: string }>(),
    'Load Service Success': props<{ service: any }>(),
    'Update Service': props<{ value: ServiceBundle }>(),
    'Clear Service': emptyProps(),

    'Load Services': props<{ contributor: string; from?: number; query?: string; status?: string; active?: boolean }>(),
    'Load Services Success': props<{ results: ServiceBundle[]; total: number; from: number }>(),
    'Clear Services': emptyProps(),

    'Load Datasource': props<{ resourceId: string }>(),
    'Load Datasource Success': props<{ datasource: any }>(),
    'Update Datasource': props<{ value: DatasourceFull }>(),
    'Clear Datasource': emptyProps(),

    'Load Datasources': props<{
      contributor: string;
      from?: number;
      query?: string;
      status?: string;
      active?: boolean;
    }>(),
    'Load Datasources Success': props<{ results: DatasourceFull[]; total: number; from: number }>(),
    'Clear Datasources': emptyProps(),

    'Load Training Resource': props<{ resourceId: string }>(),
    'Load Training Resource Success': props<{ trainingResource: any }>(),
    'Update Training Resource': props<{ value: TrainingResourceBundle }>(),
    'Clear Training Resource': emptyProps(),

    'Load Training Resources': props<{
      contributor: string;
      from?: number;
      query?: string;
      status?: string;
      active?: boolean;
    }>(),
    'Load Training Resources Success': props<{ results: TrainingResourceBundle[]; total: number; from: number }>(),
    'Clear Training Resources': emptyProps(),

    'Load Interoperability Record': props<{ resourceId: string }>(),
    'Load Interoperability Record Success': props<{ interoperabilityRecord: any }>(),
    'Update Interoperability Record': props<{ value: InteroperabilityRecordBundle }>(),
    'Clear Interoperability Record': emptyProps(),

    'Load Interoperability Records': props<{
      contributor: string;
      from?: number;
      query?: string;
      status?: string;
      active?: boolean;
    }>(),
    'Load Interoperability Records Success': props<{
      results: InteroperabilityRecordBundle[];
      total: number;
      from: number;
    }>(),
    'Clear Interoperability Records': emptyProps(),

    'Load Tool': props<{ resourceId: string }>(),
    'Load Tool Success': props<{ tool: any }>(),
    'Update Tool': props<{ value: ToolBundle }>(),
    'Clear Tool': emptyProps(),

    'Load Tools': props<{ contributor: string; from?: number; query?: string; status?: string; active?: boolean }>(),
    'Load Tools Success': props<{
      results: ToolBundle[];
      total: number;
      from: number;
    }>(),
    'Clear Tools': emptyProps(),

    'Load Vocabularies': props<{ vocabularyType: VocabularyType }>(),
    'Load Vocabularies Cached': emptyProps(),
    'Load Vocabularies Success': props<{ vocabularyType: VocabularyType; vocabulary: Vocabulary[] }>(),
    'Clear Vocabularies': emptyProps(),

    'Load Service Offers': props<{ serviceId: string }>(),
    'Load Service Offers Cached': emptyProps(),
    'Load Service Offers Success': props<{ serviceOffers: Offer[] }>(),
    'Clear Service Offers': emptyProps(),

    'Load Service Offer': props<{ value: any }>(),
    'Clear Service Offer': emptyProps(),
  },
});
