import { Injectable } from '@angular/core';
import { InteroperabilityRecord } from '../../../../interfaces/interoperability-interfaces/interoperability.interface';
import {
  ResourceTypeInfo,
  RelatedStandard,
  Right,
  Creator,
} from '../../../../interfaces/interoperability-interfaces/additionals-interoperability.interface';
import { ContributorsDashboardHelperService } from './contributors-dashboard-helper.service';
import { InteroperabilityGuidelineForm } from '../../../../interfaces/form-interfaces/interoperabilityGuidelineForm.interface';
import { VocabularyType } from '../../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../../shared/interfaces/vocabulary.interface';

@Injectable({
  providedIn: 'root',
})
export class InteroperabilityHelperService {
  interoperabilityDropdowns: VocabularyType[] = [
    VocabularyType.irNameType,
    VocabularyType.irEoscGuidelineType,
    VocabularyType.irIdentifierType,
  ];

  constructor(private contributorsDashboardHelperService: ContributorsDashboardHelperService) {}

  interoperabilityFormModelToInteroperabilityInterface(
    interoperabilytFormModel: InteroperabilityGuidelineForm
  ): InteroperabilityRecord {
    const interoperabilityRecord: InteroperabilityRecord = {
      // Information Subform
      title: interoperabilytFormModel.information.title,
      eoscGuidelineType: interoperabilytFormModel.information.eoscGuidelineType,
      publicationYear: interoperabilytFormModel.information.publicationYear,
      rights: interoperabilytFormModel.information.licenses.map((right: any) => {
        return {
          rightTitle: right.licenseName,
          rightURI: right.licenseURI,
          rightIdentifier: right.licenseIdentifier,
        };
      }),
      // Creators Subform
      creators: interoperabilytFormModel.creators.creators.map((creator: any) => {
        return {
          creatorNameTypeInfo: {
            creatorName: creator.creatorName,
            nameType: creator.creatorTitle,
          },
          givenName: creator.firstName,
          familyName: creator.lastName,
          nameIdentifier: creator.creatorsIdentifier,
          creatorAffiliationInfo: {
            affiliation: creator.affiliation,
            affiliationIdentifier: creator.affiliationIdentifier,
          },
        };
      }),
    } as InteroperabilityRecord;
    return interoperabilityRecord;
  }

  interoperabilityInterfaceToInteroperabilityFormModel(
    interoperability: InteroperabilityRecord,
    vocabularies: { [key: string]: Vocabulary[] } | null = null
  ): InteroperabilityGuidelineForm {
    const interoperabilytFormModel: InteroperabilityGuidelineForm = {
      information: {
        title: interoperability.title,
        eoscGuidelineType: interoperability.eoscGuidelineType,
        description: interoperability.description,
        publicationYear: interoperability.publicationYear,
        licenses: interoperability.rights?.length
          ? interoperability.rights.map((right: Right) => {
              return {
                licenseName: right.rightTitle,
                licenseURI: right.rightURI,
                licenseIdentifier: right.rightIdentifier,
              };
            })
          : [{ licenseName: '', licenseURI: '', licenseIdentifier: '' }],
      },
      identifierInfo: {
        identifier: interoperability.identifierInfo?.identifier,
        identifierType: interoperability.identifierInfo?.identifierType,
      },
      creators: {
        creators: interoperability.creators?.length
          ? interoperability.creators.map((creator: Creator) => {
              return {
                creatorName: creator.creatorNameTypeInfo.creatorName,
                creatorTitle: this.convertValueToLabel(
                  vocabularies,
                  VocabularyType.irNameType,
                  creator.creatorNameTypeInfo.nameType
                ),
                firstName: creator.givenName,
                lastName: creator.familyName,
                creatorsIdentifier: creator.nameIdentifier,
                affiliation: creator.creatorAffiliationInfo.affiliation,
                affiliationIdentifier: creator.creatorAffiliationInfo.affiliationIdentifier,
              };
            })
          : [
              {
                creatorName: '',
                creatorTitle: '',
                firstName: '',
                lastName: '',
                creatorsIdentifier: '',
                affiliation: '',
                affiliationIdentifier: '',
              },
            ],
      },
    };

    return interoperabilytFormModel;
  }

  prepareInteroperabilityForPreview() {
    this.interoperabilityDropdowns.forEach((vt) => {
      this.contributorsDashboardHelperService.getVocabularyType(vt);
    });
  }

  convertValueToLabel(
    vocabularies: { [key: string]: Vocabulary[] } | null,
    vocabularyType: VocabularyType,
    value: string
  ): string {
    return this.contributorsDashboardHelperService.convertValueToLabel(vocabularies, vocabularyType, value);
  }
}
